export const skills = [
  "ReactJS",
  "NodeJS",
  "TypeScript",
  "PostgreSQL",
  "MySQL",
  "MongoDB",
  "Single-SPA",
  "micro-frontend",
  "Prisma",
  "Microservices",
  "GraphQL",
  "REST APIs",
  "AWS",
  "Java",
  "Python",
  "Git",
  "CI/CD",
  "MUI",
  "TailwindCSS",
  "Bootstrap",
  "ExpressJS",
  "Terraform (IaC)",
  "HTML5",
  "CSS3",
  "JavaScript",
  "PHP",
  "tensorflow",
  "Redux",
  "jQuery",
  "Figma",
  "Adobe XD",
];
