import Navbar from "./components/Navbar";
import MainSection from "./components/MainSection";
import AboutMe from "./components/AboutMe";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="bg-primary-black w-full scroll-smooth">
      <section className="container mx-auto py-4 px-4 lg:px-0">
        <Navbar />
      </section>

      <section className="container mx-auto px-4 lg:px-0">
        <MainSection />
      </section>

      <section
        className="container mx-auto px-4 lg:px-40 mb-20 lg:mb-40"
        id="About"
      >
        <AboutMe />
      </section>

      <section
        className="container mx-auto px-4 lg:px-40 mb-20 lg:mb-40"
        id="Projects"
      >
        <Projects />
      </section>
      <section
        className="container mx-auto px-4 lg:px-40 mb-20 lg:mb-40"
        id="Experience"
      >
        <Experience />
      </section>
      <section
        className="container mx-auto px-4 lg:px-40 mb-20 lg:mb-40"
        id="Contact"
      >
        <Contact />
      </section>
    </div>
  );
}

export default App;
