export const NavLinks = [
  {
    title: "About",
    ref: "About",
  },
  {
    title: "Projects",
  },
  {
    title: "Experience",
  },
];
