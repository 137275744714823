import { FaGithub } from "react-icons/fa";
import { RiTerminalBoxFill } from "react-icons/ri";
import { FiExternalLink } from "react-icons/fi";

const Projects = () => {
  const technologiesZoodo = [
    "ReactJS",
    "MongoDB",
    "NodeJS",
    "ExpressJS",
    "TailwindCSS",
    "Tensorflow",
    "Python",
    "(CI / CD)",
    "Git",
    "REST APIs",
  ];

  const technologiesGitLab = [
    "ReactJS",
    "TailwindCSS",
    "Git",
    "GitLab REST APIs",
  ];

  const gymFit = ["ReactJS", "TailwindCSS", "Figma", "Bootstrap"];
  const starbucks = ["HTML5", "CSS3", "JavaScript", "TailwindCSS"];
  const skinConsultation = ["Java", "OOP Concepts", "JavaFX"];

  return (
    <div>
      <h1 className="text-white font-extrabold font-primary text-4xl lg:text-5xl my-8">
        <span className="text-primary-green">&lt;</span> Projects{" "}
        <span className="text-primary-green">/&gt;</span>
      </h1>

      {/* projects Div */}
      <div className="lg:mt-16">
        <div className="my-8">
          <div className="flex flex-col lg:flex-row gap-2 my-2 items-start lg:items-center">
            <div className="font-primary text-white text-2xl font-semibold">
              <span className="flex gap-2 items-center">
                <RiTerminalBoxFill className="text-primary-green" />
                ZOODO Web Application
              </span>
            </div>

            <div className="">
              <span className="flex gap-2 items-center">
                <a
                  href="https://github.com/sathmikaDev/ZOODO"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
                <span className="text-neutral-500 text-xs font-light">
                  website is not live 😟
                </span>
              </span>
            </div>
          </div>

          <p className="text-neutral-400 font-seocondary">
            software development group project - 2nd year
          </p>

          <p className="font-primary text-neutral-200 text-normal mt-4">
            Involved with creating an advanced food-ordering web application
            that utilizes deep learning techniques to Identify and clasify foods
            and predict nutrition values with the possible disabilities the
            selected food can cause when a user places an order.
          </p>
          <div className="flex items-center gap-2 flex-wrap mt-4">
            {technologiesZoodo.map((skill, index) => {
              return (
                <span className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]">
                  {skill}
                </span>
              );
            })}
          </div>
        </div>

        <div className="h-[.5px] bg-primary-green w-full "></div>

        <div className="my-8">
          <div className="flex flex-col lg:flex-row gap-2 my-2 items-start lg:items-center">
            <div className="font-primary text-white text-2xl font-semibold">
              <span className="flex gap-2 items-center">
                <RiTerminalBoxFill className="text-primary-green" />
                GitLab Project Transfer
              </span>
            </div>

            <div className="">
              <span className="flex gap-2 items-center">
                <a
                  href="https://github.com/sathmikaDev/GitLab-Project-Transfer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
                <a
                  href="https://git-lab-project-transfer.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FiExternalLink className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
              </span>
            </div>
          </div>

          <p className="text-neutral-400 font-seocondary">
            Desktop Web Application to transfer GitLab Projects.
          </p>

          <p className="font-primary text-neutral-200 text-normal mt-4">
            Users only need to provide the project ID of the project they want
            to move and the target group ID where they want to transfer the
            project.
          </p>
          <p className="font-primary text-neutral-200 text-normal mt-4">
            The application utilizes GitLab's REST APIs to interact with GitLab
            instances programmatically. This integration enables seamless
            communication between the application and GitLab, allowing for
            efficient project transfers.
          </p>
          <div className="flex items-center gap-2 flex-wrap mt-4">
            {technologiesGitLab.map((skill, index) => {
              return (
                <span
                  className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]"
                  key={index}
                >
                  {skill}
                </span>
              );
            })}
          </div>
        </div>

        <div className="h-[.5px] bg-primary-green w-full "></div>

        <div className="my-8">
          <div className="flex flex-col lg:flex-row gap-2 my-2 items-start lg:items-center ">
            <div className="font-primary text-white text-2xl font-semibold">
              <span className="flex gap-2 items-center">
                <RiTerminalBoxFill className="text-primary-green" />

                <span>
                  GymFit Web{" "}
                  <span className="text-sm text-neutral-500 font-normal">
                    (Portfolio Project)
                  </span>
                </span>
              </span>
            </div>

            <div className="">
              <span className="flex gap-2 items-center">
                <a
                  href="https://github.com/sathmikaDev/gymfit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
                <a
                  href="https://gymfit-alpha.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FiExternalLink className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
              </span>
            </div>
          </div>

          <p className="text-neutral-400 font-seocondary">
            Portfolio Web design and Development Project
          </p>

          <p className="font-primary text-neutral-200 text-normal mt-4">
            It features a responsive design, allowing seamless access across
            devices. The application is built using modern front-end web
            technologies.
          </p>
          <div className="flex items-center gap-2 flex-wrap mt-4">
            {gymFit.map((skill, index) => {
              return (
                <span
                  key={index}
                  className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]"
                >
                  {skill}
                </span>
              );
            })}
          </div>
        </div>

        <div className="h-[.5px] bg-primary-green w-full "></div>

        <div className="my-8">
          <div className="flex flex-col lg:flex-row gap-2 my-2 items-start lg:items-center ">
            <div className="font-primary text-white text-2xl font-semibold">
              <span className="flex gap-2 items-start lg:items-center">
                <RiTerminalBoxFill className="text-primary-green text-[40px] lg:text-2xl" />
                <span className="lg:flex lg:gap-2">
                  Skin Consultation Management System{" "}
                  <span className="text-sm text-neutral-500 font-normal flex gap-2 items-center mt-2">
                    (University Project)
                  </span>
                </span>
              </span>
            </div>
          </div>

          <p className="text-neutral-400 font-seocondary">
            Object Oriented Programming Module Project
          </p>

          <p className="font-primary text-neutral-200 text-normal mt-4">
            For the 2nd Year Object-Oriented Programming module Coursework, I
            designed and developed a Java application complete with a user
            interface. This application allows users to manage doctors,
            patients, and consultation reservations efficiently.
          </p>
          <div className="flex items-center gap-2 flex-wrap mt-4">
            {skinConsultation.map((skill, index) => {
              return (
                <span
                  key={index}
                  className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]"
                >
                  {skill}
                </span>
              );
            })}
          </div>
        </div>

        <div className="h-[.5px] bg-primary-green w-full "></div>

        <div className="my-8">
          <div className="flex flex-col lg:flex-row gap-2 my-2 items-start lg:items-center ">
            <div className="font-primary text-white text-2xl font-semibold">
              <span className="flex gap-2 items-center">
                <RiTerminalBoxFill className="text-primary-green" />

                <span>
                  starbucks Clone{" "}
                  <span className="text-sm text-neutral-500 font-normal">
                    (Portfolio Project)
                  </span>
                </span>
              </span>
            </div>

            <div className="">
              <span className="flex gap-2 items-center">
                <a
                  href="https://github.com/sathmikaDev/starbucks-clone"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
                <a
                  href="https://starbucks-clone-alpha.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FiExternalLink className="text-neutral-500 text-xl cursor-pointer hover:text-primary-green" />
                </a>
              </span>
            </div>
          </div>

          <p className="text-neutral-400 font-seocondary">
            Web UI Development Project
          </p>

          <p className="font-primary text-neutral-200 text-normal mt-4">
            I embarked on this project with the goal of delving deep into
            Tailwind CSS, seeking to grasp its capabilities and harness its
            power effectively. Representing one of my initial endeavors, this
            project reflects my early stages of learning and experimentation
            with Tailwind CSS.
          </p>
          <div className="flex items-center gap-2 flex-wrap mt-4">
            {starbucks.map((skill, index) => {
              return (
                <span
                  key={index}
                  className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]"
                >
                  {skill}
                </span>
              );
            })}
          </div>
        </div>

        <div>
          <h1 className="text-primary-green font-seocondary">
            Working on some thrilling projects soon to be unveiled here
            ...😎🚀👩‍💻
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Projects;
