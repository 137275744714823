import { RiTerminalBoxFill } from "react-icons/ri";
import { skills } from "../constants/skills";

const AboutMe = () => {
  return (
    <div>
      <h1 className="text-white font-extrabold font-primary text-4xl lg:text-5xl my-8">
        <span className="text-primary-green">&lt;</span> About Me{" "}
        <span className="text-primary-green">/&gt;</span>
      </h1>

      <div className="lg:mt-16">
        <p className="font-primary text-base text-white">
          I'm Kulunu Sathmika, a budding software engineer hailing from the
          captivating landscapes of Sri Lanka. Currently, I'm immersed in the
          dynamic realm of internships, where each day unveils new layers of
          innovation and learning.
        </p>
        <br />
        <p className="font-primary text-base text-white">
          Each project is a canvas, and every keystroke, a brushstroke, painting
          a future where innovation knows no bounds.With every project, I'm not
          just writing code; I'm authoring a narrative of progress. The future
          of software engineering is brimming with excitement, and I'm here to
          shape it, one line of code at a time. 🌟🚀🖥️
        </p>
        <div className="my-8">
          <span className="text-primary-green font-medium text-xl inline-flex gap-3 items-center">
            <RiTerminalBoxFill />
            My toolkit
          </span>

          <div className="flex items-center gap-2 flex-wrap mt-4">
            {skills.map((skill, index) => {
              return (
                <span className="bg-zinc-800 text-white font-seocondary font-normal rounded-md px-2 py-[0.5px]">
                  {skill}
                </span>
              );
            })}
          </div>
        </div>
        <div className="my-8">
          <span className="text-primary-green font-medium text-xl inline-flex gap-3 items-center">
            <RiTerminalBoxFill />
            Education
          </span>

          <div className="mt-4">
            <div className="flex flex-col lg:flex-row justify-between items-start">
              <div>
                <h1 className="font-semibold font-primary text-white text-lg lg:text-2xl">
                  BEng(Hons) in Software Engineering
                </h1>
                <p className="text-neutral-400 font-seocondary font-normal tracking-tight">
                  University of Westminster (Informatics Institute of Technology
                  - LK)
                </p>
              </div>
              <div className="my-2 lg:mb-0 -order-1 lg:order-last">
                <p className="text-black font-seocondary font-semibold tracking-tight bg-primary-green px-2 rounded-sm">
                  2022 - Present
                </p>
              </div>
            </div>

            <div className="h-[.5px] bg-primary-green w-full my-4"></div>

            <div className="flex flex-col lg:flex-row justify-between items-start mt-4 lg:mt-0">
              <div>
                <h1 className="font-semibold font-primary text-white text-lg lg:text-2xl">
                  G.C.E Advanced Level - Physical Science
                </h1>
                <p className="text-neutral-400 font-seocondary font-normal tracking-tight">
                  Thurstan College - Colombo 07
                </p>
                <p className="text-white font-seocondary font-normal tracking-tight mt-2">
                  Combined-Maths - C | Physics - C | Chemistry - C | General
                  English - A
                </p>
              </div>
              <div className="my-2 lg:mb-0 -order-1 lg:order-last">
                <p className="text-black font-seocondary font-semibold tracking-tight bg-primary-green px-2 rounded-sm">
                  2020
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
