import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Contact = () => {
  return (
    <div>
      <h1 className="text-white font-extrabold font-primary text-4xl lg:text-5xl my-8">
        <span className="text-primary-green">&lt;</span> Contact{" "}
        <span className="text-primary-green">/&gt;</span>
      </h1>

      <div className="lg:mt-16">
        <h1 className="text-white font-primary text-3xl lg:text-4xl font-semibold">
          Connect With Me😎🚀
        </h1>
        <p className="text-white font-seocondary mt-4 ">
          Let's collaborate and bring your ideas to life! Reach out to discuss
          projects, or just to say hello👋. Looking forward to connecting with
          you!
        </p>
        <div className="mt-8">
          <div className="text-xl items-center gap-4 flex">
            <a
              href="https://www.linkedin.com/in/kulunu-sathmika/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
            <a
              href="https://github.com/sathmikaDev"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaGithub className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
