import React from "react";

const Experience = () => {
  return (
    <div>
      <h1 className="text-white font-extrabold font-primary text-4xl lg:text-5xl my-8">
        <span className="text-primary-green">&lt;</span> Experience{" "}
        <span className="text-primary-green">/&gt;</span>
      </h1>

      <div className="lg:mt-16">
        <div className="my-8">
          <div className="flex justify-between items-start flex-col lg:flex-row gap-2">
            <div>
              <h1 className="font-semibold font-primary text-white text-2xl">
                Software Engineer - Internship
              </h1>
              <p className="text-neutral-400 font-seocondary font-normal tracking-tight text-nowrap w-1/2">
                GTN Tech - Colombo 02
              </p>
            </div>
            <div>
              <p className="text-black font-seocondary font-semibold tracking-tight bg-primary-green px-2 rounded-sm text-nowrap">
                July 2023 - July 2024.
              </p>
            </div>
          </div>

          <div className="mt-4 flex flex-col gap-2 font-primary text-base text-white font-extralight">
            <span>
              🚀 Designed and developed various efficiency-enhancing components
              and processes, leading to significant time and effort savings
              across projects.
            </span>
            <span>
              🚀 Implemented secure and scalable solutions leveraging AWS
              services, optimizing data handling and API management while
              ensuring robust security measures.
            </span>
            <span>
              🚀 Contributed to overall project stability and reliability
              through comprehensive testing, bug fixes, and streamlined
              development workflows, resulting in improved client satisfaction
              and project success.
            </span>
          </div>
        </div>

        <div className="h-[.5px] bg-primary-green w-full "></div>

        <div className="my-8">
          <div className="flex justify-between items-start flex-col lg:flex-row gap-2">
            <div>
              <h1 className="font-semibold font-primary text-white text-2xl ">
                Full-stack Developer - Freelance
              </h1>
              <p className="text-neutral-400 font-seocondary font-normal tracking-tight text-nowrap w-1/2">
                Upwork (Freelancing)
              </p>
            </div>
            <div>
              <p className="text-black font-seocondary font-semibold tracking-tight bg-primary-green px-2 rounded-sm text-nowrap">
                Jan 2024 – Contd.
              </p>
            </div>
          </div>

          <div className="mt-4 flex flex-col gap-2 font-primary text-base text-white font-extralight">
            <span>
              🚀 Leveraged ReactJS, TypeScript, and TailwindCSS to create
              intuitive and visually appealing interfaces, enhancing user
              engagement and satisfaction.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
