import { NavLinks } from "../constants/NavLinks";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);

  useEffect(() => {
    console.log(navBarOpen);
  }, [navBarOpen]);

  return (
    <nav className="">
      {!navBarOpen && (
        <div className="flex justify-between items-center rounded-full bg-secondary-black px-4 py-2">
          {/* logo */}
          <div>
            <h1 className="text-white font-primary font-extrabold text-xl lg:text-2xl">
              Sathmika
              <span className="text-primary-green font-primary font-extrabold text-xl lg:text-2xl">
                .
              </span>
            </h1>
          </div>

          {/* nav links */}
          <div className="hidden lg:block">
            <ul className="flex space-x-6">
              {NavLinks.map((link, index) => (
                <Link
                  to={link.title}
                  spy={true}
                  offset={-20}
                  duration={500}
                  smooth={true}
                  className="text-white font-seocondary text-base cursor-pointer"
                  key={index}
                >
                  {link.title}
                </Link>
              ))}
            </ul>
          </div>

          {/*  cta  */}
          <div className=" text-xl text-primary-green items-center justify-between gap-4 hidden lg:flex">
            <a
              href="https://www.linkedin.com/in/kulunu-sathmika/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
            <a
              href="https://github.com/sathmikaDev"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <FaGithub className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
          </div>

          <div className="block lg:hidden text-lg font-seocondary text-primary-green">
            <button onClick={() => setNavBarOpen(!navBarOpen)}>
              <h1 className="hover:tracking-wider"> &lt;menu&gt; </h1>
            </button>
          </div>
        </div>
      )}

      {/* mobile size menu */}
      {navBarOpen && (
        <div className="absolute top-0 left-0 bg-secondary-black text-white font-seocondary font-light h-full w-full px-8 flex justify-between flex-col transition-all duration-300 ease-in-out">
          <div className="flex justify-between items-center py-2">
            <div className="py-4">
              <h1 className="text-white font-primary font-extrabold text-xl lg:text-2xl">
                Sathmika
                <span className="text-primary-green font-primary font-extrabold text-xl lg:text-2xl">
                  .
                </span>
              </h1>
            </div>
            <div>
              <div className="block lg:hidden text-lg font-seocondary text-primary-green">
                <button onClick={() => setNavBarOpen(false)}>
                  <h1 className="hover:tracking-wider"> &lt;close&gt; </h1>
                </button>
              </div>
            </div>
          </div>

          <div className="text-center">
            <ul className="flex flex-col space-y-6">
              {NavLinks.map((link, index) => (
                <li key={link.title}>
                  <Link
                    to={link.title}
                    spy={true}
                    offset={-20}
                    duration={500}
                    smooth={true}
                    className="text-white font-seocondary text-base cursor-pointer"
                    key={index}
                    onClick={() => setNavBarOpen(false)}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="text-xl text-primary-green items-center justify-center gap-4 flex mb-40">
            <a
              href="https://www.linkedin.com/in/kulunu-sathmika/"
              target="_blank"
              rel="noreferrer"
              onClick={() => setNavBarOpen(false)}
            >
              <FaLinkedin className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
            <a
              href="https://github.com/sathmikaDev"
              target="_blank"
              rel="noreferrer"
              onClick={() => setNavBarOpen(false)}
            >
              {" "}
              <FaGithub className="cursor-pointer text-neutral-500 hover:text-primary-green" />
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
