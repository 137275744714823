import { Link } from "react-scroll";

const MainSection = () => {
  return (
    <div className="h-[90vh] flex justify-center items-center">
      <div>
        <div>
          <h1 className="text-start lg:text-center text-5xl lg:text-7xl font-primary font-extrabold text-white my-4">
            Hi, I'm Sathmika.
          </h1>
          <h1 className="text-start lg:text-center text-xl lg:text-3xl font-primary font-light text-white">
            I'm an{" "}
            <span className="bg-primary-green text-primary-black rounded-md px-1 font-semibold">
              Undergraduate Software Engineering student.
            </span>
          </h1>

          <p className="text-start lg:text-center text-base font-seocondary font-light text-white tracking-tight my-4 lg:w-2/3 mx-auto">
            I merge design and code to bring ideas to life. From intuitive user
            interfaces to visually stunning graphics, I thrive on creating
            impactful solutions. Coding isn't just a job for me😎—it's my
            creative outlet and profession. Let's build something amazing
            together!🚀👩‍💻
          </p>
        </div>

        <div className="lg:text-center text-start lg:my-16 my-12">
          <button className="bg-primary-green text-primary-black px-6 py-3 font-primary font-semibold rounded-md">
            <Link to="Contact" spy={true} smooth={true}>
              Connect with Me
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
